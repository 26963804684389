<template>
  <div class="UserInactiveSubscription">
    
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100 justify-content-center">
        <CCol class="text-center">
          <div class="w-100">
            <div class="clearfix">
              <h1 class="mr-4">❓ Oups, abonnement annulé ou expiré</h1>
              <div class="mt-4" style="font-size: 22px;">
                <p>
                  <strong>💸 L'abonnement Eclerk de votre cabinet a été annulé ou est expiré  💸</strong>
                </p>
                <p>
                  Vous pouvez contacter la personne de votre cabinet en charge de votre abonnement 
                  pour qu'elle puisse souscrire à un nouvel abonnement Eclerk
                </p>
                <p>
                  Ne vous inquiétez pas, votre compte est conservé et votre plateforme Eclerk 
                  pourra être réutilisée à l'identique dès la souscription d'un nouvel abonnement :)
                </p>
                
                <CRow class="mt-4">
                  <CCol class="text-center">
                    <CButton block shape="pill" color="outline-primary" @click="logout"> Me déconnecter </CButton>
                  </CCol>
                </CRow>
              </div>
            </div>
          </div>
        </CCol>
      </CRow>

    </CContainer>
  </div>
</template>

<script>

export default {
  name: 'UserInactiveSubscription',
  
  methods: {
    
    logout () {
      this.$store.dispatch('AUTH_LOGOUT').then(() => {
        this.$router.push('/pages/login')
      })
    }
  }
}
</script>
